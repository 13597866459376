import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-blank-layout',
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.scss']
})

export class BlankComponent {

  constructor(private router: Router,) {
    
  }

  sair() {
    localStorage.clear();
    this.router.navigate(['/authentication/login']);
  }
}
