import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { AngularFireAuth } from "angularfire2/auth";
import { Observable } from 'rxjs/Observable';
import 'rxjs/Rx';
import * as firebase from 'firebase/app';
import { identifierName } from '@angular/compiler';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as moment from 'moment' ;
import { APIService } from '../providers/api';

@Injectable()
export class FirebaseService {

    feeBoxes: number = 0;
    feeCrossfute: number = 0;
    checkinsQnt: number = 0;

    constructor(
        private afs: AngularFirestore,
        private afAuth: AngularFireAuth,
        private http: HttpClient,
        public apiService: APIService
    ) {
    }

    //HTTP
    invoices() {
        return new Promise((resolve, reject) => {
            this.http.get(environment.apiUrl+'invoices').subscribe((res: any) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        })
    }
    transactions() {
        return new Promise((resolve, reject) => {
            this.http.get(environment.apiUrl+'transactions').subscribe((res: any) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        })
    }
    createSeller(data) {
        return new Promise((resolve, reject) => {
            this.http.post(environment.apiUrl+'new-seller', data).subscribe((res: any) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        })
    }
    createPlan(data) {
        return new Promise((resolve, reject) => {
            this.http.post(environment.apiUrl+'new-plan', data).subscribe((res: any) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        })
    }

    //Remove user from box
    removeUserFromAdminBox(user) {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('users').doc(user.uid).update({
                adminBox: '',
                box: '',
            })
                .then(() => {
                    resolve()
                })
        })
    }

    //Auth
    login(user) {
        return new Promise((resolve, reject) => {
            this.afAuth.auth.signInWithEmailAndPassword(user.email, user.password)
                .then((res) => {
                    let uid = res.uid;

                    this.afs.firestore.collection('users').doc(uid).get()
                        .then((u) => {
                            let uu = u.data();
                            resolve(uu)
                        })
                })
                .catch(() => {
                    reject()
                })
        })
    }


    signup(user) {
        return new Promise((resolve, reject) => {
            let data = {
                name : user.name,
                email : user.email,
                password : user.pass,
                boxId : user.boxId,
                contract : user.contract ? user.contract : null,
                status : 0, 
                planActive : 0,
                userSellerId: user.userSellerId ? user.userSellerId : null,
            }
            this.http.post(environment.internalAPI + 'user', data).subscribe((response) => {
                try {
                    this.afAuth.auth.createUserWithEmailAndPassword(user.email, user.pass)
                    .then((res) => {
                        try {
                            let uid = res.uid;
                            let toSave = {
                                name: user.name,
                                lastName: '',
                                email: user.email,
                                uid: uid,
                                checkins: 0,
                                plan: null,
                                avatar: null,
                                //createdAt: new Date(),
                                status: 0,
                                planActive: false,
                                role: user.role ? user.role : 2,
                                boxId: user.boxId,
                                userSellerId: user.userSellerId ? user.userSellerId : null,
                                contract : user.contract ? user.contract : null
                            }
                            
                            this.afs.firestore.collection('log_register_autentication').add(user);
                            this.afs.firestore.collection('users').doc(uid).set(toSave)
                            .then((u) => {
                                this.afs.firestore.collection('users').doc(uid).update({createdAt: new Date()});
                                resolve(toSave);
                            })
                            .catch((error) => {
                                var data = {
                                    where : 'SaveUser',
                                    body  : JSON.stringify(toSave)
                                };
                                this.afs.firestore.collection('errors').add(data);
                                reject(error);
                            })
                        } catch (error) {
                            this.afs.firestore.collection('log_register_error_autentication').add(user);
                        }

                    })
                    .catch((error) => {
                        var data = {
                            where   : 'Authenticate',
                            body    : JSON.stringify(user),
                            error   : JSON.stringify(error)
                        }
                        this.afs.firestore.collection('errors').add(data);
                        reject(error);
                    });
                } catch (error) {
                    this.afAuth.auth.signInWithEmailAndPassword(user.email, user.password)
                    .then((res) => {
                        try {
                            let uid = res.uid;
                            let toSave = {
                                name: user.name,
                                lastName: '',
                                email: user.email,
                                uid: uid,
                                checkins: 0,
                                plan: null,
                                avatar: null,
                                //createdAt: new Date(),
                                status: 0,
                                planActive: false,
                                role: user.role ? user.role : 2,
                                boxId: user.boxId,
                                userSellerId: user.userSellerId ? user.userSellerId : null,
                                contract : user.contract ? user.contract : null
                            }
                            
                            this.afs.firestore.collection('log_register_autentication').add(user);
                            this.afs.firestore.collection('users').doc(uid).set(toSave)
                            .then((u) => {
                                this.afs.firestore.collection('users').doc(uid).update({createdAt: new Date()});
                                resolve(toSave);
                            })
                            .catch((error) => {
                                var data = {
                                    where : 'SaveUser',
                                    body  : JSON.stringify(toSave)
                                };
                                this.afs.firestore.collection('errors').add(data);
                                reject(error);
                            })
                        } catch (error) {
                            this.afs.firestore.collection('log_register_error_autentication').add(user);
                        }
                    })
                    .catch(() => {
                        reject()
                    })
                }
                
            });
            
        })
    }

    logRegister(user) {
      try {
        this.afs.firestore.collection('log_register').add(user)
        .then(() => {
            console.log('registrou intenção');
        })
        .catch((error) => {
            this.afs.firestore.collection('log_register_error').add(error);
        })
      } catch (error) {
        this.afs.firestore.collection('log_register_error_cacth').add(error);
      }
    }

    getGestores(id) {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('users')
                .where('box', '==', id)
                .get()
                .then((us) => {
                    let users = [];
                    us.forEach((u) => {
                        let obj = u.data();
                        obj.id = u.id;
                        if (obj.role !== 2) {
                            users.push(obj)
                        }
                    });

                    resolve(users)
                })
        })
    }

    // Criar boxes
    // Criando clientes internos
    register(params, boxId) {
        return new Promise((resolve, reject) => {
            this.afAuth.auth.createUserWithEmailAndPassword(params.email, params.password)
                .then((user) => {
                    let toSave = {
                        name: 'Nome',
                        lastName: 'Sobrenome',
                        email: params.email,
                        uid: user.uid,
                        checkins: 0,
                        plan: null,
                        avatar: null,
                        createdAt: moment(),
                        status: 1,
                        role: 9,
                        adminBox: boxId
                    }
                    this.afs.firestore.collection('users').doc(user.uid).set(toSave)
                        .then(() => {
                            resolve(toSave)
                        })
                })
                .catch(() => {
                    reject()
                })
        })
    }
    createCard(data) {
        return new Promise((resolve, reject) => {
            this.http.post(environment.apiUrl +'new-card', data).subscribe((res: any) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        })
    }
    createBuyer(data) {
        return new Promise((resolve, reject) => {
            this.http.post(environment.apiUrl +'new-buyer', data).subscribe((res: any) => {
                resolve(res);
            }, (err) => {
                var data = {
                    where   : 'CreateBuyer',
                    body    : JSON.stringify(err)
                };
                this.afs.firestore.collection('errors').add(data);
                reject(err);
            });
        })
    }

    assingCard(data){
        return new Promise((resolve,reject) => {
            this.http.post(environment.apiUrl + 'new-card-assign', data).subscribe((res: any) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        });
    }

    updateBuyer(id, data){
        return new Promise((resolve,reject) => {
            this.http.post(environment.apiUrl + 'buyers/' + id, data).subscribe((res: any) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        });
    }

    newSubscription(data) {
        return new Promise((resolve, reject) => {
            this.http.post(environment.apiUrl + 'new-subscription', data).subscribe((res: any) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        });
    }

    cancelSubscription(id) {
        return new Promise((resolve, reject) => {
            this.http.post(environment.apiUrl + 'cancel-subscriptions/' + id, {}).subscribe((res: any) => {
                resolve(res);
            }, (err) => {
                console.log(err);

                reject(err);
            });
        });
    }

    reTransact(id) {
        return new Promise((resolve, reject) => {
            this.http.post(environment.apiUrl + 'retransact-subscriptions/' + id, {}).subscribe((res: any) => {
                resolve(res);
            }, (err) => {
                console.log(err);

                reject(err);
            });
        });
    }

    createBoxDebit(data) {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('box-debits').add(data)
            .then((res) => {
                resolve(res);
            }).catch((error)=> {
                reject(error)
            })
        });
    }

    removeBoxDebit(id) {
        return this.afs.firestore.collection('box-debits').doc(id).delete();
    }

    getBoxDebits(boxId) {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('box-debits')
                .where('boxId', '==', boxId)
                .get()
                .then((debs) => {
                    let debits = [];
                    debs.forEach((d) => {
                        let obj = d.data();
                        obj.id = d.id;
                        debits.push(obj)
                    });

                    resolve(debits)
                })
        })
    }

    newUserSubscription(data){
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('subscriptions').add(data)
            .then((res) => {
                data.id = res.id;
                data.firebaseId = res.id;
                data.userId     = data.user;
                this.http.post(environment.internalAPI + 'subscription', data)
                .subscribe((resp) => {
                    resolve(data);
                });
                
            }).catch((error)=> {
                console.log(error);
                
                reject(error)
            });
        });
    }

    //Verify user
    verifyUserByEmail(email) {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('users')
                .where('email', '==', email)
                .get()
                .then((us) => {
                    let users = [];
                    us.forEach((u) => {
                        let obj = u.data();
                        obj.id = u.id;
                        users.push(obj)
                    });

                    resolve(users)
                })
        })
    }

    updateUser(user) {
        let data = user;
        return new Promise((resolve, reject) => {
            this.http.put(environment.internalAPI + 'user/' + user.uid, data).subscribe((response) => {
                user.updatedAt = new Date();
                this.afs.firestore.collection('users').doc(user.uid).update(data)
                .then(() => {
                    resolve(user)
                })
            });
        })
    }

    getTotalTransactions(startDate, endDate, type) {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('transactions')
                .where('created_at', '>=', startDate)
                .where('created_at', '<=', endDate)
                .where('type', '==', type)
                .get()
                .then((res) => {
                    let total = 0;
                    res.forEach((u) => {
                        let obj = u.data();
                        total += parseFloat(obj.payload.object.amount);
                    });

                    resolve(total)
                })
        })
    }

    getTransactionsByCustomer(customer, filter = '') {
        let arrayReturn = [];
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('transactions')
            .where('payload.object.customer', '==', customer)
            .orderBy('created_at', 'desc')
            .get()
            .then((res) => {
                res.forEach((t) => {
                    let transaction = t.data();
                    if(transaction.type.includes('transaction')  ){
                        if( filter !== '' ){
                            if(transaction.type.includes(filter)  ){
                                arrayReturn.push(transaction.payload.object);
                            }
                        } else {
                            arrayReturn.push(transaction.payload.object);
                        }
                    }
                });

                resolve(arrayReturn);
            })
        })
    }

    //Leads
    listLeads() {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('leads')
                .orderBy('createdAt', 'desc')
                .get()
                .then((us) => {
                    let users = [];
                    us.forEach((u) => {
                        let obj = u.data();
                        obj.id = u.id;
                        obj.createdAt = new Date(obj.createdAt)
                        users.push(obj)
                    });

                    resolve(users)
                })
        })
    }
    listLeads10() {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('leads')
                .orderBy('createdAt', 'desc')
                .limit(10)
                .get()
                .then((us) => {
                    let users = [];
                    us.forEach((u) => {
                        let obj = u.data();
                        obj.id = u.id;
                        obj.createdAt = new Date(obj.createdAt)
                        users.push(obj)
                    });

                    resolve(users)
                })
        })
    }
    listLead(id) {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('leads').doc(id)
                .get()
                .then((u) => {
                    let obj = u.data();
                    obj.id = u.id;
                    obj.status = obj.status.toString()
                    obj.oportunity = obj.oportunity.toString()
                    obj.call = obj.call.toString()
                    obj.sendedMail = obj.sendedMail.toString()

                    resolve(obj)
                })
        })
    }
    saveLead(user) {
        return this.afs.firestore.collection('leads').doc(user.id).update(user)
    }
    addLead(user) {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('leads')
                .add(user)
                .then((res) => {
                    let id = res.id;
                    this.afs.firestore.collection('leads').doc(id).update({ id: id })
                        .then(() => {
                            resolve()
                        })
                })
        })
    }

    //Users
    newUser(user) {
        return new Promise((resolve, reject) => {
            this.afAuth.auth.createUserWithEmailAndPassword(user.email, user.pass)
                .then((res) => {
                    let uid = res.uid;
                    let toSave = {
                        name        : user.name,
                        lastName    : user.lastName ? user.lastName : '',
                        email       : user.email,
                        uid         : uid,
                        checkins    : 0,
                        plan        : null,
                        avatar      : null,
                        createdAt   : new Date(),
                        status      : 1,
                        cutOffDate  : 1,
                        role        : parseInt(user.role),
                        adminBox    : user.adminBox,
                        box         : null,
                        cpf         : user.cpf ? user.cpf : null
                    }
                    if( toSave.role == 9 || toSave.role == 5 ){
                        toSave.box = user.box;
                    }
                    this.afs.firestore.collection('users').doc(uid).set(toSave)
                        .then(() => {
                            this.apiService.checkLead({
                                email : user.email, 
                                boxId : user.adminBox
                            });

                            resolve(toSave)
                        })
                })
                .catch(() => {
                    reject()
                })
        })
    }
    saveUser(user) {
        let data = user;
        data.role = parseInt(data.role);
        return new Promise((resolve, reject) => {
            this.http.put(environment.internalAPI + 'user/' + user.email, data).subscribe((response) => {
                user.updatedAt = new Date();
                this.afs.firestore.collection('users').doc(user.uid).update(data)
                .then(() => {
                    resolve(user)
                })
            });
        })
    }
    getColabs() {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('users')
                .orderBy('name')
                .get()
                .then((res) => {
                    let i = [];
                    res.forEach((r) => {
                        let user = r.data();
                        if(user.role != 2){
                            i.push(r.data());
                        }
                    })

                    resolve(i)
                })
        })
    }
    getInstructors() {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('users')
                .where('role', '>=', 3)
                .orderBy('role', 'asc')
                .orderBy('name', 'asc')
                .get()
                .then((res) => {
                    let i = [];
                    res.forEach((r) => {
                        i.push(r.data());
                    })

                    resolve(i)
                })
        })
    }
    instructors(id) {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('users')
                .where('role', '>=', 3)
                .where('box', '==', id)
                .orderBy('name', 'asc')
                .get()
                .then((res) => {
                    let i = [];
                    res.forEach((r) => {
                        i.push(r.data());
                    })
                    resolve(i)
                })
        })
    }

    checkIfUserExists(email) {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('users')
                .where('email', "==", email)
                .get()
                .then((res) => {
                    let i = [];
                    res.forEach((r) => {
                        i.push(r.data());
                    })
                    resolve(i.length);
                })
        })
    }

    //Financeiro
    getCategories() {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('finance-categories')
                .orderBy('createdAt', 'desc')
                .get()
                .then((res) => {
                    let i = [];
                    res.forEach((r) => {
                        i.push(r.data());
                    })

                    resolve(i)
                })
        })
    }
    getItens(id) {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('finance-costs')
                .where('categorieId', '==', id)
                .orderBy('createdAt', 'desc')
                .get()
                .then((res) => {
                    let i = [];
                    res.forEach((r) => {
                        i.push(r.data());
                    })

                    resolve(i)
                })
        })
    }
    deleteItem(collection, c) {
        return this.afs.collection(collection).doc(c.id).delete()
    }
    newCost(item) {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('finance-costs').add(item)
                .then((r) => {
                    let id = r.id;
                    this.afs.firestore.collection('finance-costs').doc(id).update({ id: id })
                        .then(() => {
                            resolve()
                        })
                })
        })
    }
    newCat(item) {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('finance-categories').add(item)
                .then((r) => {
                    let id = r.id;
                    this.afs.firestore.collection('finance-categories').doc(id).update({ id: id })
                        .then(() => {
                            resolve()
                        })
                })
        })
    }
    saveCost(item) {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('finance-costs').doc(item.id).update(item)
                .then((r) => {
                    resolve()
                })
        })
    }
    saveCat(item) {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('finance-categories').doc(item.id).update(item)
                .then((r) => {
                    resolve()
                })
        })
    }

    //Plans
    listPlans() {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('plans')
                .orderBy('name', 'desc')
                .get()
                .then((res) => {
                    let items = [];
                    res.forEach((r) => {
                        let obj = r.data();
                        obj.id = r.id;
                        items.push(obj)
                    });

                    resolve(items)
                })
        })
    }
    newPlan(plan) {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('plans').add(plan)
                .then((r) => {
                    let id = r.id;
                    this.afs.firestore.collection('plans').doc(id).update({ id: id })
                        .then(() => {
                            resolve()
                        })
                })
        })
    }
    savePlan(p) {
        return new Promise((resolve, reject) => {
            p.updatedAt = new Date();
            this.afs.firestore.collection('plans').doc(p.id).update(p)
                .then((r) => {
                    resolve()
                })
        })
    }

    //Check-ins
    qntCheckinsByMonth9(user) {
        return new Promise((resolve, reject) => {
            let date = new Date();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();
            let s = year + '-' + month + '-01';
            let e = year + '-' + month + '-31'
            let start = new Date(s);
            let end = new Date(e);

            this.afs.firestore.collection('check-ins')
                .where('checkinAt', '>', start)
                .where('checkinAt', '<', end)
                .where('boxId', '==', user.adminBox)
                .get()
                .then((res) => {
                    let items = [];
                    res.forEach((r) => {
                        let obj = r.data();
                        obj.id = r.id;
                        items.push(obj)
                    });

                    resolve(items)
                })
        })
    }
    qntCheckinsByMonth() {
        return new Promise((resolve, reject) => {
            let date = new Date();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();
            let s = year + '-' + month + '-01';
            let e = year + '-' + month + '-31'
            let start = new Date(s);
            let end = new Date(e);

            this.afs.firestore.collection('check-ins')
                .where('checkinAt', '>', start)
                .where('checkinAt', '<', end)
                .get()
                .then((res) => {
                    let items = [];
                    res.forEach((r) => {
                        let obj = r.data();
                        obj.id = r.id;
                        items.push(obj)
                    });

                    resolve(items)
                })
        })
    }
    getCheckinsByFilterUser(m, u) {
        return new Promise((resolve, reject) => {
            let date = new Date();
            let month = m + 1;
            let year = date.getFullYear();
            let s = year + '-' + month + '-01';
            let e = year + '-' + month + '-31'
            let start = new Date(s);
            let end = new Date(e);

            this.afs.firestore.collection('check-ins')
                .where('userId', '==', u.uid)
                .where('checkinAt', '>', start)
                .where('checkinAt', '<', end)
                .orderBy('checkinAt', 'desc')
                .get()
                .then((res) => {
                    let items = [];
                    let promises = [];
                    let promises2 = [];
                    let users = [];
                    let boxes = [];
                    res.forEach((r) => {
                        let obj = r.data();
                        obj.id = r.id;
                        items.push(obj)

                        promises.push(
                            this.afs.firestore.collection('users')
                                .where('uid', '==', obj.userId)
                                .get()
                        );
                    });

                    Promise.all(promises).then((res) => {
                        res.forEach((r) => {
                            r.forEach((y) => {
                                let obj = y.data();
                                obj.id = y.id;
                                obj.fullName = obj.name + " " + obj.lastName;
                                users.push(obj);
                            })

                            let i = 0;
                            for (i; i < items.length; i++) {
                                promises2.push(
                                    this.afs.firestore.collection('boxes')
                                        .where('id', '==', items[i].boxId)
                                        .get()
                                );
                            }
                        });

                        Promise.all(promises2).then((res) => {
                            res.forEach((r) => {
                                r.forEach((y) => {
                                    let obj = y.data();
                                    obj.id = y.id;
                                    boxes.push(obj);
                                })
                            });

                            //Build list
                            let i = 0;
                            for (i; i < items.length; i++) {
                                let item = items[i];
                                sessionStorage.setItem('crossfuteUid', item.userId);
                                sessionStorage.setItem('crossfuteBox', item.boxId);
                                var user = users.find(this.CallbackFunctionToFindUser);
                                item.user = user;
                                var box = boxes.find(this.CallbackFunctionToFindBox);
                                item.box = box;
                            };

                            resolve(items);
                        })
                    })
                })
        })
    }
    getCheckinsByFilterMonthAndBox(m, b, y = null) {

        return new Promise((resolve, reject) => {
            let date = new Date();
            let month = parseInt(m) + 1;
            let year = date.getFullYear();
            if( y )
                year = y;
            let s = year + '-' + month + '-01';
            let e = year + '-' + month + '-31'
            let start = new Date(s);
            let end = new Date(e);
            this.afs.firestore.collection('check-ins')
                .where('boxId', '==', b)
                .where('checkinAt', '>', start)
                .where('checkinAt', '<', end)
                .orderBy('checkinAt', 'desc')
                .get()
                .then((res) => {
                    let items = [];
                    let promises = [];
                    let promises2 = [];
                    let users = [];
                    let boxes = [];
                    res.forEach((r) => {
                        let obj = r.data();
                        obj.id = r.id;
                        items.push(obj)
                    });

                    resolve(items);
                })
        })
    }
    getCheckinsByFilterMonth(m, y = null) {

        return new Promise((resolve, reject) => {
            let date = new Date();
            let month = parseInt(m) + 1;
            let year = date.getFullYear();
            if( y )
                year = y;
            let s = year + '-' + month + '-01';
            let e = year + '-' + month + '-31'
            let start = new Date(s);
            let end = new Date(e);

            this.afs.firestore.collection('check-ins')
                .where('checkinAt', '>', start)
                .where('checkinAt', '<', end)
                .orderBy('checkinAt', 'desc')
                .get()
                .then((res) => {
                    let items = [];
                    let promises = [];
                    let promises2 = [];
                    let users = [];
                    let boxes = [];
                    res.forEach((r) => {
                        let obj = r.data();
                        obj.id = r.id;
                        items.push(obj)

                    });
                    resolve(items);

                })
        })
    }
    getCheckinsByFilterBox(m, b, y = null) {

        m = parseInt(m);

        return new Promise((resolve, reject) => {
            let date = new Date();
            let month = m + 1;
            let year = date.getFullYear();
            if( y )
                year = y;
            let s = year + '-' + month + '-01';
            let e = year + '-' + month + '-31'
            let start = new Date(s);
            let end = new Date(e);

            this.afs.firestore.collection('check-ins')
                .where('boxId', '==', b)
                .where('checkinAt', '>', start)
                .where('checkinAt', '<', end)
                .orderBy('checkinAt', 'desc')
                .get()
                .then((res) => {
                    let items = [];
                    let promises = [];
                    let promises2 = [];
                    let users = [];
                    let boxes = [];
                    res.forEach((r) => {
                        let obj = r.data();
                        obj.id = r.id;
                        items.push(obj)

                        promises.push(
                            this.afs.firestore.collection('users')
                                .where('uid', '==', obj.userId)
                                .get()
                        );
                    });

                    Promise.all(promises).then((res) => {
                        res.forEach((r) => {
                            r.forEach((y) => {
                                let obj = y.data();
                                obj.id = y.id;
                                obj.fullName = obj.name + " " + obj.lastName;
                                users.push(obj);
                            })

                            let i = 0;
                            for (i; i < items.length; i++) {
                                promises2.push(
                                    this.afs.firestore.collection('boxes')
                                        .where('id', '==', items[i].boxId)
                                        .get()
                                );
                            }
                        });

                        Promise.all(promises2).then((res) => {
                            res.forEach((r) => {
                                r.forEach((y) => {
                                    let obj = y.data();
                                    obj.id = y.id;
                                    boxes.push(obj);
                                })
                            });

                            //Build list
                            let i = 0;
                            for (i; i < items.length; i++) {
                                let item = items[i];
                                sessionStorage.setItem('crossfuteUid', item.userId);
                                sessionStorage.setItem('crossfuteBox', item.boxId);
                                var user = users.find(this.CallbackFunctionToFindUser);
                                item.user = user;
                                var box = boxes.find(this.CallbackFunctionToFindBox);
                                item.box = box;
                            };

                            resolve(items);
                        })
                    })
                })
        })
    }
    getCheckinsByFilterUserBox(m, b, u) {
        return new Promise((resolve, reject) => {
            let date = new Date();
            let month = m + 1;
            let year = date.getFullYear();
            let s = year + '-' + month + '-01';
            let e = year + '-' + month + '-31'
            let start = new Date(s);
            let end = new Date(e);

            this.afs.firestore.collection('check-ins')
                .where('userId', '==', u.uid)
                .where('boxId', '==', b)
                .where('checkinAt', '>', start)
                .where('checkinAt', '<', end)
                .orderBy('checkinAt', 'desc')
                .get()
                .then((res) => {
                    let items = [];
                    let promises = [];
                    let promises2 = [];
                    let users = [];
                    let boxes = [];
                    res.forEach((r) => {
                        let obj = r.data();
                        obj.id = r.id;
                        items.push(obj)

                        promises.push(
                            this.afs.firestore.collection('users')
                                .where('uid', '==', obj.userId)
                                .get()
                        );
                    });

                    Promise.all(promises).then((res) => {
                        res.forEach((r) => {
                            r.forEach((y) => {
                                let obj = y.data();
                                obj.id = y.id;
                                obj.fullName = obj.name + " " + obj.lastName;
                                users.push(obj);
                            })

                            let i = 0;
                            for (i; i < items.length; i++) {
                                promises2.push(
                                    this.afs.firestore.collection('boxes')
                                        .where('id', '==', items[i].boxId)
                                        .get()
                                );
                            }
                        });

                        Promise.all(promises2).then((res) => {
                            res.forEach((r) => {
                                r.forEach((y) => {
                                    let obj = y.data();
                                    obj.id = y.id;
                                    boxes.push(obj);
                                })
                            });

                            //Build list
                            let i = 0;
                            for (i; i < items.length; i++) {
                                let item = items[i];
                                sessionStorage.setItem('crossfuteUid', item.userId);
                                sessionStorage.setItem('crossfuteBox', item.boxId);
                                var user = users.find(this.CallbackFunctionToFindUser);
                                item.user = user;
                                var box = boxes.find(this.CallbackFunctionToFindBox);
                                item.box = box;
                            };

                            resolve(items);
                        })
                    })
                })
        })
    }

    //Check-ins
    getCheckinsByUser(user) {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('check-ins')
                .orderBy('checkinAt', 'desc')
                .where('userId', '==', user)
                .get()
                .then((res) => {
                    let items = [];
                    res.forEach((i) => {
                        let item = i.data();
                        items.push(item);
                    });

                    resolve(items)
                })
        })
    }

    getCheckinsByUserAndMonth(user) {
        return new Promise((resolve, reject) => {
            let date = new Date();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();
            let s = year + '-' + month + '-01';
            let e = year + '-' + month + '-31'
            let start = new Date(s);
            let end = new Date(e);

            this.afs.firestore.collection('check-ins')
                .orderBy('checkinAt', 'desc')
                .where('userId', '==', user)
                .where('checkinAt', '>', start)
                .where('checkinAt', '<', end)
                .get()
                .then((res) => {
                    let items = [];
                    res.forEach((i) => {
                        let item = i.data();
                        items.push(item);
                    });

                    resolve(items)
                })
        })
    }

    getCheckinsBySubscriptionByBox(id) {
        return new Promise((resolve, reject) => {
            let date = new Date();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();
            let s = year + '-' + month + '-01';
            let e = year + '-' + month + '-31'
            let start = new Date(s);
            let end = new Date(e);

            this.afs.firestore.collection('check-ins')
                .orderBy('checkinAt', 'desc')
                .where('checkinAt', '>', start)
                .where('checkinAt', '<', end)
                .where('boxId', '==', id)
                .get()
                .then((res) => {
                    let items = [];
                    let promises = [];
                    let promises2 = [];
                    let users = [];
                    let plans = [];
                    res.forEach((r) => {
                        let obj = r.data();
                        obj.id = r.id;
                        items.push(obj)

                        promises.push(
                            this.afs.firestore.collection('users')
                                .where('uid', '==', obj.userId)
                                .get()
                        );
                    });

                    Promise.all(promises).then((res) => {
                        res.forEach((r) => {
                            r.forEach((y) => {
                                let obj = y.data();
                                obj.id = y.id;
                                users.push(obj);
                            })

                            let i = 0;
                            for (i; i < items.length; i++) {
                                promises2.push(
                                    this.afs.firestore.collection('plans')
                                        .where('id', '==', items[i].actualPlan)
                                        .get()
                                );
                            }
                        });

                        Promise.all(promises2).then((res) => {
                            res.forEach((r) => {
                                r.forEach((y) => {
                                    let obj = y.data();
                                    obj.id = y.id;
                                    plans.push(obj);
                                })
                            });

                            //Build list
                            let i = 0;
                            for (i; i < items.length; i++) {
                                let item = items[i];
                                sessionStorage.setItem('crossfuteUid', item.userId);
                                var user = users.find(this.CallbackFunctionToFindUser);
                                item.user = user;
                                sessionStorage.setItem('crossfutePlan', item.user.plan);
                                var plan = plans.find(this.CallbackFunctionToFindPlan);
                                item.plan = plan;
                            };

                            let obj = this.calc(items);
                            resolve(obj)
                        })
                    })
                })
        })
    }

    getCheckinsBySubscriptionUser(user, date = null) {
        return new Promise((resolve, reject) => {

            let query = this.afs.firestore.collection('check-ins')
            .orderBy('checkinAt', 'desc')
            .where('userId', '==', user);

            if( date ){
                let start = moment(date).startOf('month').toDate();
                let end = moment(date).endOf('month').toDate();
                query = query
                .where('checkinAt', '>', start)
                .where('checkinAt', '<', end)
            }

            query.get()
                .then((res) => {
                    let items = [];
                    let promises = [];
                    let promises2 = [];
                    let users = [];
                    let plans = [];
                    res.forEach((r) => {
                        let obj = r.data();
                        obj.id = r.id;
                        items.push(obj)

                        promises.push(
                            this.afs.firestore.collection('users')
                                .where('uid', '==', obj.userId)
                                .get()
                        );
                    });

                    Promise.all(promises).then((res) => {
                        res.forEach((r) => {
                            r.forEach((y) => {
                                let obj = y.data();
                                obj.id = y.id;
                                users.push(obj);
                            })

                            let i = 0;
                            for (i; i < items.length; i++) {
                                promises2.push(
                                    this.afs.firestore.collection('plans')
                                        .where('id', '==', items[i].actualPlan)
                                        .get()
                                );
                            }
                        });

                        Promise.all(promises2).then((res) => {
                            res.forEach((r) => {
                                r.forEach((y) => {
                                    let obj = y.data();
                                    obj.id = y.id;
                                    plans.push(obj);
                                })
                            });

                            //Build list
                            let i = 0;
                            for (i; i < items.length; i++) {
                                let item = items[i];
                                sessionStorage.setItem('crossfuteUid', item.userId);
                                var user = users.find(this.CallbackFunctionToFindUser);
                                item.user = user;
                                sessionStorage.setItem('crossfutePlan', item.user.plan);
                                var plan = plans.find(this.CallbackFunctionToFindPlan);
                                item.plan = plan;
                            };

                            let obj = this.calc(items);
                            resolve(obj)
                        })
                    })
                })
        })
    }

    getCheckinsBySubscription() {
        return new Promise((resolve, reject) => {
            let date = new Date();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();
            let s = year + '-' + month + '-01';
            let e = year + '-' + month + '-31'
            let start = new Date(s);
            let end = new Date(e);

            this.afs.firestore.collection('check-ins')
                .orderBy('checkinAt', 'desc')
                .where('checkinAt', '>', start)
                .where('checkinAt', '<', end)
                .get()
                .then((res) => {
                    let items = [];
                    let promises = [];
                    let promises2 = [];
                    let users = [];
                    let plans = [];
                    res.forEach((r) => {
                        let obj = r.data();
                        obj.id = r.id;
                        items.push(obj)

                        promises.push(
                            this.afs.firestore.collection('users')
                                .where('uid', '==', obj.userId)
                                .get()
                        );
                    });

                    Promise.all(promises).then((res) => {
                        res.forEach((r) => {
                            r.forEach((y) => {
                                let obj = y.data();
                                obj.id = y.id;
                                users.push(obj);
                            })

                            let i = 0;
                            for (i; i < items.length; i++) {
                                promises2.push(
                                    this.afs.firestore.collection('plans')
                                        .where('id', '==', items[i].actualPlan)
                                        .get()
                                );
                            }
                        });

                        Promise.all(promises2).then((res) => {
                            res.forEach((r) => {
                                r.forEach((y) => {
                                    let obj = y.data();
                                    obj.id = y.id;
                                    plans.push(obj);
                                })
                            });

                            //Build list
                            let i = 0;
                            for (i; i < items.length; i++) {
                                let item = items[i];
                                sessionStorage.setItem('crossfuteUid', item.userId);
                                var user = users.find(this.CallbackFunctionToFindUser);
                                item.user = user;
                                sessionStorage.setItem('crossfutePlan', item.user.plan);
                                var plan = plans.find(this.CallbackFunctionToFindPlan);
                                item.plan = plan;
                            };

                            let obj = this.calc(items);
                            resolve(obj)
                        })
                    })
                })
        })
    }

    calc(result) {
        ///CALCULO
        let i = 0;
        let checkins = [];
        for (i; i < result.length; i++) {
            this.checkinsQnt = this.checkinsQnt + 1;
            let item = result[i];
            // let fee = item.plan.fee;
            // let feeC = item.plan.feeCrossfute;
            // let checkinAmount = item.plan.price / item.plan.checkins;
            //
            // let checkinFeeBox = checkinAmount * (fee / 100);
            // let checkinFeeCrossfute = checkinAmount * (feeC / 100);
            //
            // this.feeBoxes = this.feeBoxes + checkinFeeBox;
            // this.feeCrossfute = this.feeCrossfute + checkinFeeCrossfute;
            // item.feeBox = checkinFeeBox;
            //
            // item.feeCrossfute = checkinFeeCrossfute
            checkins.push(item);
        }

        let obj = {
            // feeCrossfute: this.feeCrossfute,
            // feeBoxes: this.feeBoxes,
            // checkinsQnt: this.checkinsQnt,
            checkins: checkins
        }
        return obj;
    }

    //Get costs
    getCostsRecurrent() {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('finance-costs')
                .where('recurrent', '==', true)
                .where('type', '==', 0)
                .get()
                .then((res) => {
                    let items = [];
                    res.forEach((r) => {
                        let obj = r.data();
                        obj.id = r.id;
                        items.push(obj)
                    });
                    resolve(items)
                })
        })
    }
    getCostsNoRecurrent() {
        return new Promise((resolve, reject) => {
            let m = new Date().getMonth() + 1;
            this.afs.firestore.collection('finance-costs')
                .where('recurrent', '==', false)
                .where('month', '==', m)
                .where('type', '==', 0)
                .get()
                .then((res) => {
                    let items = [];
                    res.forEach((r) => {
                        let obj = r.data();
                        obj.id = r.id;
                        items.push(obj)
                    });

                    resolve(items)
                })
        })
    }
    getCostsRecurrentBox() {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('finance-costs')
                .where('recurrent', '==', true)
                .where('type', '==', 1)
                .get()
                .then((res) => {
                    let items = [];
                    res.forEach((r) => {
                        let obj = r.data();
                        obj.id = r.id;
                        items.push(obj)
                    });

                    resolve(items)
                })
        })
    }
    getCostsNoRecurrentBox() {
        return new Promise((resolve, reject) => {
            let m = new Date().getMonth() + 1;
            this.afs.firestore.collection('finance-costs')
                .where('recurrent', '==', false)
                .where('month', '==', m)
                .where('type', '==', 1)
                .get()
                .then((res) => {
                    let items = [];
                    res.forEach((r) => {
                        let obj = r.data();
                        obj.id = r.id;
                        items.push(obj)
                    });

                    resolve(items)
                })
        })
    }

    getCheckins() {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('check-ins')
                .orderBy('checkinAt', 'desc')
                .get()
                .then((res) => {
                    let items = [];
                    let promises = [];
                    let promises2 = [];
                    let users = [];
                    let boxes = [];
                    res.forEach((r) => {
                        let obj = r.data();
                        obj.id = r.id;
                        items.push(obj)

                        promises.push(
                            this.afs.firestore.collection('users')
                                .where('uid', '==', obj.userId)
                                .get()
                        );
                    });

                    Promise.all(promises).then((res) => {
                        res.forEach((r) => {
                            r.forEach((y) => {
                                let obj = y.data();
                                obj.id = y.id;
                                users.push(obj);
                            })

                            let i = 0;
                            for (i; i < items.length; i++) {
                                promises2.push(
                                    this.afs.firestore.collection('boxes')
                                        .where('id', '==', items[i].boxId)
                                        .get()
                                );
                            }
                        });

                        Promise.all(promises2).then((res) => {
                            res.forEach((r) => {
                                r.forEach((y) => {
                                    let obj = y.data();
                                    obj.id = y.id;
                                    boxes.push(obj);
                                })
                            });

                            //Build list
                            let i = 0;
                            for (i; i < items.length; i++) {
                                let item = items[i];
                                sessionStorage.setItem('crossfuteUid', item.userId);
                                sessionStorage.setItem('crossfuteBox', item.boxId);
                                var user = users.find(this.CallbackFunctionToFindUser);
                                item.user = user;
                                var box = boxes.find(this.CallbackFunctionToFindBox);
                                item.box = box;
                            };

                            resolve(items);
                        })
                    })
                })
        })
    }

    CallbackFunctionToFindUser(user) {
        let uid = sessionStorage.getItem('crossfuteUid');
        return user.uid === uid;
    }
    CallbackFunctionToFindBox(box) {
        let id = sessionStorage.getItem('crossfuteBox');
        return box.id === id;
    }
    CallbackFunctionToFindPlan(plan) {
        let id = sessionStorage.getItem('crossfutePlan');
        return plan.id === id;
    }

    //Users
    getUsersByBox(user) {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('users')
                .where('role', '==', 0)
                .where('box', '==', user.adminBox)
                .get()
                .then((res) => {
                    let array = [];
                    res.forEach((r) => {
                        let obj = r.data();
                        obj.SubId = r.id;
                        obj.fullName = obj.name + " " + obj.lastName;
                        array.push(obj);
                    });

                    resolve(array)
                })
        })
    }
    getUsers() {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('users')
                .where('role', '==', 2)
                .orderBy('name', 'asc')
                .get()
                .then((res) => {
                    let array = [];
                    res.forEach((r) => {
                        let obj = r.data();
                        obj.SubId = r.id;
                        obj.fullName = obj.name + " " + obj.lastName;
                        array.push(obj);
                    });

                    resolve(array)
                })
        })
    }
    // getUsersByMonth() {
    //     return new Promise((resolve, reject) => {
    //         this.afs.firestore.collection('users')
    //             .where('role', '==', 2)
    //             .orderBy('name', 'asc')
    //             .get()
    //             .then((res) => {
    //                 let array = [];
    //                 res.forEach((r) => {
    //                     let obj = r.data();
    //                     obj.SubId = r.id;
    //                     obj.fullName = obj.name + " " + obj.lastName;
    //                     array.push(obj);
    //                 });

    //                 resolve(array)
    //             })
    //     })
    // }
    changeUserStatus(uid, num) {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('users')
                .doc(uid)
                .update({ 'status': num })
                .then(() => {
                    resolve()
                });
        })
    }

    //Clients
    getActiveClientsByBox(user) {
        return new Promise((resolve, reject) => {
            let array = [];
            this.afs.firestore.collection('users')
                .where('box', '==', user.adminBox)
                .where('status', '==', 1)
                .get()
                .then((res) => {
                    res.forEach((r) => {
                        let obj = r.data();
                        obj.id = r.id;
                        array.push(obj);
                    });

                    resolve(array)
                })
        })
    }

    //Subscriptions
    getSubscriptions() {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('subscriptions')
                .where('status', '==', 'active')
                .get()
                .then((res) => {
                    let amount = 0;
                    res.forEach((r) => {
                        let obj = r.data();
                        amount = amount + obj.amount;
                    });

                    resolve(amount)
                })
        })
    }
    getSubscriptionsListByBox(box) {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('subscriptions')
                .where('status', '==', 'active')
                .where('boxId', '==', box)
                .get()
                .then((res) => {
                    let array = [];
                    res.forEach((r) => {
                        let obj = r.data();
                        obj.SubId = r.id;
                        array.push(obj);
                    });

                    resolve(array)
                })
        })
    }

    getSubscriptionsListByUser(userId) {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('subscriptions')
                .where('user', '==', userId)
                .get()
                .then((res) => {
                    let array = [];
                    res.forEach((r) => {
                        let obj = r.data();
                        obj.SubId = r.id;
                        array.push(obj);
                    });

                    resolve(array)
                })
        })
    }

    getSubscriptionsList() {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('subscriptions')
                .where('status', '==', 'active')
                .get()
                .then((res) => {
                    let array = [];
                    res.forEach((r) => {
                        let obj = r.data();
                        obj.SubId = r.id;
                        array.push(obj);
                    });

                    resolve(array)
                })
        })
    }

    getFirstSubscriptionByUser(user) {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('subscriptions')
            .where('user', '==', user)
            .orderBy('createdAt', 'asc')
            .limit(1)
            .get()
            .then((res) => {
                let items = [];
                res.forEach((r) => {
                    let obj = r.data();
                    obj.id = r.id;
                    items.push(obj);
                });

                resolve(items);
            })
        })
    }

    getSubscriptionByUser(user) {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('subscriptions')
                .where('status', '==', 'active')
                .where('user', '==', user)
                .get()
                .then((res) => {
                    let items = [];
                    res.forEach((r) => {
                        let obj = r.data();
                        obj.id = r.id;
                        items.push(obj);
                    });

                    resolve(items);
                })
        })
    }

    updateSubscription(subscription) {
        let data = subscription;
        return new Promise((resolve, reject) => {
            subscription.updatedAt = new Date();
            this.afs.firestore.collection('subscriptions').doc(data.id).update(data)
            .then(() => {
                this.http.put(environment.internalAPI + 'subscription/' + data.id, data)
                .subscribe((resp) => {
                    resolve(data)  
                });
                
            })
        })
    }

    getPlan(plan) {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('plans')
                .where('id', '==', plan)
                .get()
                .then((res) => {
                    let items = [];
                    res.forEach((r) => {
                        let obj = r.data();
                        items.push(obj);
                    });

                    resolve(items);
                })
        })
    }

    getSubscriptionsByMonth() {
        return new Promise((resolve, reject) => {
            let date = new Date();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();
            let s = year + '-' + month + '-01 00:00:00';
            let e = year + '-' + month + '-31 23:59:59'
            let start = new Date(s);
            let end = new Date(e);

            this.afs.firestore.collection('subscriptions')
                .where('createdAt', '>=', start)
                .where('createdAt', '<=', end)
                .get()
                .then(async (res) => {
                    let items = [];
                    await res.forEach(async(r) => {
                        let obj = r.data(); 
                        obj.id = r.id;
                        items.push(obj) 
                    });

                    resolve(items)
                })
        })
    }
    getSubscriptionsByMonthAndBox(box = null, monthRequest = null) {
        return new Promise((resolve, reject) => {
            let date = new Date(monthRequest);

            let month = date.getMonth() + 1;
            let year = date.getFullYear();
            let s = year + '-' + month + '-01 00:00:00';
            let e = year + '-' + month + '-31 23:59:59'
            let start = new Date(s);
            let end = new Date(e);

            let subscriptions = this.afs.firestore.collection('statements')
            .where('type', '==', 'registration')
            .where('createdAt', '>=', start)
            .where('createdAt', '<=', end);

            if( box )
                subscriptions = subscriptions.where('box.id', '==', box);

            subscriptions.get()
            .then((res) => {
                let items = [];
                res.forEach((r) => {
                    let obj = r.data();
                    obj.id = r.id;
                    items.push(obj)
                });

                resolve(items)
            })
        })
    }

    //Boxes
    getBoxesSpecific(id) {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('boxes')
                .doc(id)
                .get()
                .then((res) => {
                    if( res.exists ) {
                        let obj = res.data();
                        obj.id = res.id;
                        obj.qntCheckins = 0;

                        resolve(obj);
                    }

                    resolve(null);
                })
        })
    }
    getBoxes() {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('boxes')
                .orderBy('createdAt', 'desc')
                .get()
                .then((res) => {
                    let items = [];
                    res.forEach((r) => {
                        let obj = r.data();
                        obj.id = r.id;
                        obj.qntCheckins = 0;
                        items.push(obj)
                    });

                    resolve(items)
                })
        })
    }

    deleteBox(box) {
        return this.afs.firestore.collection('boxes').doc(box.id).delete()
    }

    getUniqueBox(id) {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('boxes')
                .where('id', '==', id)
                .get()
                .then((res) => {
                    let items = [];
                    res.forEach((r) => {
                        let obj = r.data();
                        obj.id = r.id;
                        items.push(obj)
                    });

                    resolve(items)
                })
        })
    }

    getBox(b) {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('users')
                .where('box', '==', b.id)
                .where('role', '==', 2)
                .get()
                .then((res) => {
                    let items = [];
                    res.forEach((r) => {
                        let obj = r.data();
                        obj.id = r.id;
                        items.push(obj)
                    });

                    let box = b;
                    box.subscriptionsQnt = items.length;
                    resolve(box)
                })
        })
    }

    getBoxInfo(b) {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('boxes')
                .where('id', '==', b.id)
                .get()
                .then((res) => {
                    let items = [];
                    res.forEach((r) => {
                        let obj = r.data();
                        obj.id = r.id;
                        items.push(obj)
                    });
                    ;
                    resolve(items[0])
                })
        })
    }

    saveBox(box) {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('boxes').doc(box.id).update(box)
                .then((res) => {
                    resolve()
                })
        })
    }

    newBox(box) {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('boxes').add(box)
                .then((res) => {
                    let id = res.id;
                    this.afs.firestore.collection('boxes').doc(id).update({
                        id: id,
                        createdAt: new Date()
                    })
                        .then(() => {
                            resolve(id)
                        })
                })
        })
    }

    //Users
    qntUsersByMonth() {
        return new Promise((resolve, reject) => {
            let date = new Date();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();
            let s = year + '-' + month + '-01';
            let e = year + '-' + month + '-31'
            let start = new Date(s);
            let end = new Date(e);

            this.afs.firestore.collection('users')
                .where('createdAt', '>', start)
                .where('createdAt', '<', end)
                .get()
                .then((res) => {
                    let items = [];
                    res.forEach((r) => {
                        let obj = r.data();
                        obj.id = r.id;
                        items.push(obj)
                    });

                    resolve(items)
                })
        })
    }

    transaction(data){
        return new Promise((resolve, reject) => {
            this.http.post(environment.apiUrl + 'transaction', data).subscribe((res: any) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        })
    }

    checkTransaction(id) {
        return new Promise((resolve, reject) => {
            this.http.get(environment.apiUrl + 'transaction/'+id+'/check').subscribe((res: any) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        })
    }

    cancelTransaction(id, data){
        return new Promise((resolve, reject) => {
            this.http.post(environment.apiUrl + 'transaction/' + id + '/void', data).subscribe((res: any) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        })
    }


    /**
     * Funções criadas pelo Raphael
     */

    /**
     * Retorna todos os clientes do Box
     */
    getBoxClients(boxId, start, end): Promise<Array<object>>{
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('users')
                .where('boxId', '==', boxId)
                .get()
                .then((responseClients) => {

                    let promises = [];
                    let clients = [];
                    responseClients.forEach((c) => {

                        var client = c.data();
                        clients.push(client);

                        // Pegar os checkins desse cliente
                        promises.push(
                            this.afs.firestore.collection('check-ins')
                                .where('userId', '==', client["uid"])
                                .where('checkinAt', '>', start)
                                .where('checkinAt', '<', end)
                                .get()
                        );
                    });

                    Promise.all(promises).then((res) => {
                        res.forEach((r) => {
                            r.forEach((y) => {
                                var checkIn = y.data();
                                var clientReference = clients.find(c => c.uid == checkIn.userId);
                                if (clientReference["checkInsObj"] == undefined) clientReference["checkInsObj"] = [];
                                clientReference["checkInsObj"].push(checkIn);
                            });
                        });

                        resolve(clients)
                    });
                })
        })
    }

    getBoxTickets(boxId, start, end): Promise<Array<object>> {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('check-ins')
                .where('boxId', '==', boxId)
                .where('checkinAt', '>', start)
                .where('checkinAt', '<', end)
                .get()
                .then((checkins) => {

                    let items = [];
                    let promises = [];

                    checkins.forEach((data) => {
                        var checkin = data.data();
                        items.push(checkin);

                        promises.push(
                            this.afs.firestore.collection('users')
                                .doc(checkin["userId"])
                                .get()
                        );
                    });

                    Promise.all(promises).then((res) => {
                        res.forEach((data) => {
                            var user = data.data();
                            for (let i = 0; i < items.length; i++) {
                                if(items[i]["userId"] == user["uid"]){
                                    items[i]["user"] = user;
                                }
                            }
                        });

                    });
                    // Pegar o usuário


                    resolve(items);
                })
        })
    }

    getCheckinsBySubscriptionAndBox(id) {
        return new Promise((resolve, reject) => {
            let date = new Date();
            let month = date.getMonth() + 1;
            let year = date.getFullYear();
            let s = year + '-' + month + '-01';
            let e = year + '-' + month + '-31'
            let start = new Date(s);
            let end = new Date(e);

            this.afs.firestore.collection('check-ins')
                .orderBy('checkinAt', 'desc')
                .where('checkinAt', '>', start)
                .where('checkinAt', '<', end)
                .where('boxId', '==', id)
                .get()
                .then((res) => {
                    let items = [];
                    let promises = [];
                    let promises2 = [];
                    let users = [];
                    let plans = [];
                    res.forEach((r) => {
                        let obj = r.data();
                        obj.id = r.id;
                        items.push(obj)

                        promises.push(
                            this.afs.firestore.collection('users')
                                .where('uid', '==', obj.userId)
                                .get()
                        );
                    });

                    Promise.all(promises).then((res) => {
                        res.forEach((r) => {
                            r.forEach((y) => {
                                let obj = y.data();
                                obj.id = y.id;
                                users.push(obj);
                            })

                            let i = 0;
                            for (i; i < items.length; i++) {
                                promises2.push(
                                    this.afs.firestore.collection('plans')
                                        .where('id', '==', items[i].actualPlan)
                                        .get()
                                );
                            }
                        });

                        Promise.all(promises2).then((res) => {
                            res.forEach((r) => {
                                r.forEach((y) => {
                                    let obj = y.data();
                                    obj.id = y.id;
                                    plans.push(obj);
                                })
                            });

                            //Build list
                            let i = 0;
                            for (i; i < items.length; i++) {
                                let item = items[i];
                                sessionStorage.setItem('crossfuteUid', item.userId);
                                var user = users.find(this.CallbackFunctionToFindUser);
                                item.user = user;
                                sessionStorage.setItem('crossfutePlan', item.user.plan);
                                var plan = plans.find(this.CallbackFunctionToFindPlan);
                                item.plan = plan;
                            };
                            resolve(items);
                        })
                    })
                })
        })
    }

    /**
     * Realiza um saque
     *
     * @param boxId String
     */
    redeem(boxId)
    {
        return new Promise((resolve, reject) => {
            this.http.post(environment.apiUrl + 'box/'+boxId+'/redeem', {}).subscribe((res: any) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        })
    }

    getUser(userId) {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('users')
                .where('uid', '==', userId)
                .get()
                .then((res) => {
                    let items = [];
                    res.forEach((r) => {
                        let obj = r.data();
                        items.push(obj)
                    });
                    ;
                    resolve(items[0])
                })
        })
    }

    checkin(box, user, date) {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('users').doc(user.uid).get()
            .then((u) => {
                let uu = u.data();
                if( uu.stauts == 0 || uu.planActive == false ){
                    reject(uu);
                    return false;
                }

                let checkin = {
                    "checkinAt" : date,
                    "boxId"     : box.id,
                    "userId"    : user.uid,
                    "actualPlan": user.plan,
                    "boxName"   : box.name,
                    "boxCity"   : box.city,
                    "boxState"  : box.state,
                    "user"      : user
                }

                this.afs.firestore.collection('check-ins').add(checkin)
                .then((res) => {
                    resolve(res);
                }).catch((error)=> {
                    reject(error)
                })

            })
        })

    }

    getTransactionsByFilterMonth(m, user) {
        return new Promise((resolve, reject) => {
            let date = moment().toDate();
            let month = parseInt(m) + 1;
            let year = date.getFullYear();
            let s = year + '-' + month + '-01';
            let e = year + '-' + month + '-31'
            let start = new Date(s);
            let end = new Date(e);

            this.afs.firestore.collection('transactions')
                .where('created_at', '>', start)
                .where('created_at', '<', end)
                .orderBy('created_at', 'desc')
                .get()
                .then((res) => {
                    let items = [];
                    let array = [];
                    res.forEach((r) => {
                        let obj = r.data();
                        if( obj.type.includes('transaction') ){

                            if(obj.payload.object)
                                obj.payload = obj.payload.object;
                            if( !items[obj.payload.id] ){
                                items[obj.payload.id] = obj;

                                if( user.role === 9 && ( !obj.user || obj.user.boxId !== user.adminBox ) )
                                    return;

                                obj.id = r.id;
                                array.push(obj.payload);
                            }
                        }
                    });

                    resolve(array);
                })
                .catch((error) => {
                    console.log(error);
                });
        })
    }

    getUserByEmail(email) {
        return new Promise((resolve, reject) => {

            this.afs.firestore.collection('users')
            .where('email', '==', email)
            .limit(1)
            .get()
            .then((res) => {
                if( res.docs.length > 0 ){
                    const snapshot = res.docs[0];
                    const data = snapshot.data();

                    resolve(data);
                } else {
                    reject();
                }
            })
            .catch(() => {
                reject();
            });
        })
    }

    getUserByCustomer(customer) {
        return new Promise((resolve, reject) => {
            this.afs.firestore.collection('subscriptions')
                .where('customer', '==', customer)
                .limit(1)
                .get()
                .then((res) => {
                    if( res.docs.length > 0 ){
                        const snapshot = res.docs[0];
                        const data = snapshot.data();

                        this.afs.firestore.collection('users')
                        .doc(data.user)
                        .get()
                        .then((res) => {
                            let response = res.data();
                            resolve(response);
                        })
                        .catch(() => {
                            reject();
                        });
                    } else {
                        this.afs.firestore.collection('users')
                        .where('buyer.id', '==', customer)
                        .limit(1)
                        .get()
                        .then((res) => {
                            if( res.docs.length > 0 ){
                                const snapshot = res.docs[0];
                                const data = snapshot.data();

                                resolve(data);
                            } else {
                                reject();
                            }
                        })
                        .catch(() => {
                            reject();
                        });
                    }
                })
        })
    }

    getWalletBox(boxId, date) {
        date     = moment(date);
        let firstDay = moment(date.startOf('month').format('YYYY-MM-DD 00:00')).toDate();
        let lastDay  = moment(date.endOf('month').format('YYYY-MM-DD 23:59')).toDate();

        return new Promise((resolve, reject) => {
            return this.afs.firestore.collection('statements')
            .where('createdAt', '>=', firstDay)
            .where('createdAt', '<=', lastDay)
            .where('box.id', '==', boxId)
            .get()
            .then((query) => {
                console.log(query.docs);
                let statements = [];
                query.forEach((c) => {
                    let data = c.data();
                    data.id = c.id;
                    statements.push(data);
                });

                resolve(statements);
            }).catch((error) => {
                reject(error);
            });
        })
    }
}
