// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  on_behalf_of: '9e7f3d23b6744da6a2d80bc494e9827c', //'a3a8f706f63243949d49bd20e8c37972', Esse é o de desenvolvimento
  //apiUrl: 'https://api.funfute.com.br/'
  apiUrl: 'https://us-central1-tbx-crossfute.cloudfunctions.net/api/',
  internalAPI: 'https://api.crossfute.com.br/api/',
  // apiUrl: 'http://localhost:5001/tbx-crossfute/us-central1/api/'
  // apiUrl          : 'http://app.funfute.com.br/api/'

};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
