import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-sharedlink',
  templateUrl: './sharedlink.component.html',
  styleUrls: ['./sharedlink.component.css']
})
export class SharedlinkComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    public router: Router
  ) {
    let user_id = this.route.snapshot.paramMap.get("user_id");
    let box_id = this.route.snapshot.paramMap.get("box_id");
    localStorage.setItem('wizardUserId', user_id);
    localStorage.setItem('wizardBox', box_id);
    this.router.navigate(['/authentication/signup']);
  }

  ngOnInit() {
  }

}
