import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { AuthGuardService } from './guards/auth-guard.service';

import { SharedlinkComponent } from './authentication/sharedlink/sharedlink.component';
import { UploadComponent } from './authentication/upload/upload.component';

export const Approutes: Routes = [
  {
    path: 'admin',
    component: FullComponent,
    canActivate: [AuthGuardService],
    data: { role: 1 },
    children: [
      {
        path: '',
        redirectTo: 'dashboard/resumo',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: './dashboards/dashboard.module#DashboardModule'
      },
      //INIT PAGES
      {
        path: 'leads',
        loadChildren: './leads/leads.module#LeadsModule'
      },
      {
        path: 'boxes',
        loadChildren: './boxes/boxes.module#BoxesModule'
      },
      {
        path: 'users',
        loadChildren: './users/users.module#UsersModule'
      },
      {
        path: 'planos',
        loadChildren: './planos/planos.module#PlanosModule'
      },
      {
        path: 'checkins',
        loadChildren: './checkins/checkins.module#CheckinsModule'
      },
      {
        path: 'transaction',
        loadChildren: './transaction/transaction.module#TransactionModule'
      },
      {
        path: 'evaluation',
        loadChildren: './evaluation/evaluation.module#EvaluationModule'
      },
      {
        path: 'wallet',
        loadChildren: './wallet/wallet.module#WalletModule'
      },
      {
        path: 'colaboradores',
        loadChildren: './instructors/instructors.module#InstructorsModule'
      },
      {
        path: 'financeiro',
        loadChildren: './financeiro/financeiro.module#FinanceiroModule'
      },
      //END PAGES
      {
        path: 'starter',
        loadChildren: './starter/starter.module#StarterModule'
      },
      {
        path: 'component',
        loadChildren: './component/component.module#ComponentsModule'
      },
      { path: 'cards', loadChildren: './cards/cards.module#CardsModule' },
      { path: 'icons', loadChildren: './icons/icons.module#IconsModule' },
      { path: 'forms', loadChildren: './form/forms.module#FormModule' },
      { path: 'tables', loadChildren: './table/tables.module#TablesModule' },
      { path: 'charts', loadChildren: './charts/charts.module#ChartModule' },
      {
        path: 'widgets',
        loadChildren: './widgets/widgets.module#WidgetsModule'
      },
      { path: 'ecom', loadChildren: './ecommerce/ecom.module#EcomModule' },
      {
        path: 'timeline',
        loadChildren: './timeline/timeline.module#TimelineModule'
      },
      {
        path: 'extra-component',
        loadChildren:
          './extra-component/extra-component.module#ExtraComponentModule'
      },
      { path: 'apps', loadChildren: './apps/apps.module#AppsModule' },
      { path: 'maps', loadChildren: './maps/maps.module#MapsModule' },
      {
        path: 'sample-pages',
        loadChildren: './sample-pages/sample-pages.module#SamplePagesModule'
      }
    ]
  },
  {
    path: '',
    component: BlankComponent,
    canActivate: [AuthGuardService],
    data: { role: 2 },
    children: [
      {
        path: '',
        redirectTo: '/profile',
        pathMatch: 'full'
      },
      {
        path: 'profile',
        component: FullComponent,
        loadChildren: './profile/profile.module#ProfileModule'
      },
      {
        path: 'wizard',
        loadChildren: './wizard/wizard.module#WizardModule'
      }
    ]
  },
  {
    path: '',
    component: BlankComponent,
    children: [
      {
        path: 'w/:box_id/:user_id',
        component: SharedlinkComponent
      },
      {
        path: 'authentication',
        loadChildren: './authentication/authentication.module#AuthenticationModule'
      }
    ]
  },
  {
    path: 'u/:box_id',
    component: UploadComponent
  },
  {
    path: '**',
    redirectTo: '/authentication/404'
  }
  
];
