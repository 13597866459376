import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private router: Router
  ) { }
  private isAuthenticated: boolean = false;

  // canActivate() {
  //   return this.isAuthenticated;
  // }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // const user = this._authService.decode();
    const user = JSON.parse(localStorage.getItem('tbx_user'));
    if (user) {
      if([1,2,9].includes(user.role))
        return true;

      this.router.navigate(['/authentication/login']);
      return false;
    }
    else {
      //redirect to auth
      this.router.navigate(['/authentication/login']);
      return false;
    }
  }
}