import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '/admin',
    title: 'Dashboard',
    icon: 'fa fa-fw fa-dashboard',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/admin/colaboradores/lista',
    title: 'Colaboradores',
    icon: 'fa fa-fw fa-users',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/admin/users/lista',
    title: 'Clientes',
    icon: 'fa fa-fw fa-running',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/admin/checkins/lista',
    title: 'Check-ins',
    icon: 'fa fa-fw fa-check',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/admin/boxes/lista',
    title: "CT's",
    icon: 'fa fa-fw fa-futbol',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/admin/leads/lista',
    title: 'Leads',
    icon: 'fa fa-fw fa-user-friends',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/admin/evaluation/lista',
    title: 'Avaliações',
    icon: 'mdi mdi-account-star',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/admin/financeiro/detalhes',
    title: 'Gestão de custos',
    icon: 'fa fa-fw fa-dollar-sign',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/admin/wallet/extrato-admin',
    title: 'Extrato dos CT\'S',
    icon: 'fa fa-fw fa-wallet',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/admin/wallet/extrato-central',
    title: 'Extrato Central',
    icon: 'fa fa-fw fa-money-check',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/admin/transaction/lista',
    title: 'Transações',
    icon: 'fas fa-receipt',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/admin/planos/lista',
    title: 'Planos',
    icon: 'fa fa-fw fa-money-bill',
    class: '',
    extralink: false,
    submenu: []
  }
];
