import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseService } from '../../providers/firebase';
import { HttpClient, HttpRequest, HttpHeaders, HttpEvent } from '@angular/common/http';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  
  loading = false;
  saved = false;
  password = null;
  box = {
    idFalefy : null
  };

  constructor(
    public http: HttpClient,
    public firebaseService: FirebaseService,
    private route: ActivatedRoute,
    public router: Router
  ) {
    let box_id = this.route.snapshot.paramMap.get("box_id");
    console.log(box_id);
    this.firebaseService.getBoxesSpecific(box_id).then((result:any) => {
      this.box = result;
      console.log(this.box);
      
    })
    
    // this.router.navigate(['/authentication/signup']);
  }

  ngOnInit() {
  }

  save() {
    this.loading = true;
    let token = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2FwcC5mYWxlZnkuY29tL2xvZ2luIiwiaWF0IjoxNjU4MzQyNDkwLCJleHAiOjE2NzYzNDYwOTAsIm5iZiI6MTY1ODM0MjQ5MCwianRpIjoiNU8xR0Z0ampkdTRZWVRIMyIsInN1YiI6OTUwLCJwcnYiOiI4N2UwYWYxZWY5ZmQxNTgxMmZkZWM5NzE1M2ExNGUwYjA0NzU0NmFhIn0.PhhU0KaS-_bkkuh0nTBYC9PuivpnV_kcPlC1VjVxjFM';
    const headers = new HttpHeaders()
    .set('Authorization', token);

    let file = (<HTMLInputElement>document.getElementById('certFile')).files[0];
    const formData: FormData = new FormData();
    formData.append('certificate', file);
    formData.append('cert_password', this.password);

    const req = new HttpRequest('POST',`https://app.falefy.com/api/client/${this.box.idFalefy}/save_cert`, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: headers
    });

    this.http.request(req).subscribe(response => {
      console.log(response);
      this.saved = true;
      this.loading = false;
    });


  }
}
