import { Component, AfterViewInit, OnInit } from '@angular/core';
import { ROUTES } from './menu-items';
import { RouteInfo } from './sidebar.metadata';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  showMenu = '';
  showSubMenu = '';
  public sidebarnavItems: any[];
  user: any;

  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  // End open close
  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('tbx_user'));
    switch( this.user.role ){
      case 1 :
        this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
      break;

      case 9 : 
        const menuCT: RouteInfo[] = [
          {
            path: '/admin',
            title: 'Dashboard',
            icon: 'fa fa-fw fa-dashboard',
            class: '',
            extralink: false,
            submenu: []
          },
          {
            path: '/admin/wallet/extrato-admin',
            title: 'Extrato',
            icon: 'fa fa-fw fa-wallet',
            class: '',
            extralink: false,
            submenu: []
          },
          {
            path: '/admin/users/lista',
            title: 'Clientes',
            icon: 'fa fa-fw fa-running',
            class: '',
            extralink: false,
            submenu: []
          },
          {
            path: '/admin/checkins/lista',
            title: 'Check-ins',
            icon: 'fa fa-fw fa-check',
            class: '',
            extralink: false,
            submenu: []
          },
          {
            path: '/admin/transaction/lista',
            title: 'Transações',
            icon: 'fas fa-receipt',
            class: '',
            extralink: false,
            submenu: []
          },
          {
            path: '/admin/evaluation/lista',
            title: 'Avaliações',
            icon: 'mdi mdi-account-star',
            class: '',
            extralink: false,
            submenu: []
          }
        ];
        this.sidebarnavItems = menuCT.filter(sidebarnavItem => sidebarnavItem);
      break;

      case 2 :
        const menuUser: RouteInfo[] = [
          {
            path: '/profile',
            title: 'Perfil',
            icon: 'fa fa-fw fa-user',
            class: '',
            extralink: false,
            submenu: []
          },
        ];
        this.sidebarnavItems = menuUser.filter(sidebarnavItem => sidebarnavItem);
      break;
    }

  }

  sair() {
    localStorage.clear();
    this.router.navigate(['/authentication/login']);
  }
}
