import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from "angularfire2/firestore";
import { AngularFireAuth } from "angularfire2/auth";
import { Observable } from 'rxjs/Observable';
import 'rxjs/Rx';
import * as firebase from 'firebase/app';
import { identifierName } from '@angular/compiler';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class APIService {

    feeBoxes: number = 0;
    feeCrossfute: number = 0;
    checkinsQnt: number = 0;

    constructor(
        private afAuth: AngularFireAuth,
        private http: HttpClient
    ) {
    }

    //HTTP
    createP2PTransfer(receiver, amount, description, date) {
        return new Promise((resolve, reject) => {
            this.http.post(environment.apiUrl + 'transfer', { receiver: receiver, "amount": amount, "description": description, "transfer_date": date }).subscribe((res: any) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        })
    }

    /**
     * Retorna os dados para o relatório de extrato
     *
     * @param boxId
     * @param date
     */
    getWalletStatement(boxId,date) {
        return new Promise((resolve, reject) => {
            this.http.get(environment.apiUrl + 'box/'+boxId+'/walletStatement', {
                params: {
                    boxId: boxId,
                    date: date
                }
            }).subscribe((res: any) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        })
    }

    /**
     * Retorna o saldo disponível na zoop do Box
     *
     * @param boxId String
     */
    getMarketPlaceBalance(boxId) {
        return new Promise((resolve, reject) => {
            this.http.get(environment.apiUrl + boxId + '/balance').subscribe((res: any) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        })
    }

    getAPITransactions(boxId, month) {
        return new Promise((resolve, reject) => {
            this.http.get(environment.internalAPI + 'transactions', {
                params : {
                    boxId: boxId,
                    date: month
                }
            }).subscribe((res: any) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        })
    }
    getAPICustomerTransactions(customerEmail, status = null) {
        return new Promise((resolve, reject) => {
            this.http.get(environment.internalAPI + 'transactions', {
                params : {
                    customer_email  : customerEmail,
                    status : status
                }
            }).subscribe((res: any) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        })
    }

    getReport(startDate, endDate, boxId = null) {
        return new Promise((resolve, reject) => {
            this.http.get(environment.internalAPI + 'statement/report', {
                params : {
                    start_date  : startDate,
                    end_date    : endDate,
                    box_id      : boxId
                }
            }).subscribe((res: any) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        })
    }

    // financial funcitons
    getFinancials(date, boxId) {
        return new Promise((resolve, reject) => {
            this.http.get(environment.internalAPI + 'financial', {
                params : {
                    date    : date,
                    boxId   : boxId
                }
            }).subscribe((res: any) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        })
    }
    saveFinancial(financial) {
        return new Promise((resolve, reject) => {
            this.http.post(environment.internalAPI + 'financial', financial)
            .subscribe((res: any) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        })
    }
    getLeads(params) {
        return new Promise((resolve, reject) => {
            this.http.get(environment.internalAPI + 'lead', {
                params: params
            }).subscribe((res: any) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        })
    }
    getLead(id) {
        return new Promise((resolve, reject) => {
            this.http.get(environment.internalAPI + 'lead/' + id)
            .subscribe((res: any) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        })
    }
    updateLead(data, id) {
        return new Promise((resolve, reject) => {
            this.http.put(environment.internalAPI + 'lead/' + id, data)
            .subscribe((res: any) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        })
    }
    checkLead(data) {
        return new Promise((resolve, reject) => {
            this.http.post(environment.internalAPI + 'lead/checkout', data)
            .subscribe((res: any) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        })
    }
    saveTransaction(data) {
        return new Promise((resolve, reject) => {
            this.http.post(environment.internalAPI + 'transaction', data)
            .subscribe((res: any) => {
                resolve(res);
            }, (err) => {
                reject(err);
            });
        })
    }
}
